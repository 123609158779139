import React from 'react';
import Hero from '../components/section1/Hero';
import Project from '../components/section2/Project';
import Services from '../components/section3/Services';
import About from '../components/section4/About';


export default function Home() {
 console.log('Hello')
  return (
    <div  >
      <Hero />
      <Project />
      <Services />
      <About />
    </div>
  )
}
