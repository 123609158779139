import React from 'react';
import './Hero.css';
import underline from '../../assets/Vector.svg';

export default function Hero() {
  return (
    <div className='hero-container' id='home'>
        <div className='hero-content'>
            <div className='hero-heading-container'>
                <h1>Enthusiastic <span>JavaScript Developer</span></h1>
                <img src={underline} alt="underline" className='hero-heading-underline' />
            </div>
            <div className='hero-content-highlight'>
                <div className='hero-cards'>
                    <h3>JavaScript</h3>
                </div>
                <div className='hero-cards'>
                    <h3>TypeScript</h3>
                </div>
                <div className='hero-cards'>
                    <h3>React Native</h3>
                </div>
                <div className='hero-cards'>
                    <h3>React</h3>
                </div>
                <div className='hero-cards'>
                    <h3>Redux</h3>
                </div>
                <div className='hero-cards'>
                    <h3>Firebase</h3>
                </div>
            </div>
        </div>
    </div>
  )
}
