import React from 'react';
import './services.css';
import {GiSpiderWeb,GiAbstract061} from 'react-icons/gi'
import { FaMobileAlt } from "react-icons/fa";

export default function Services() {

    const serviceData=[
        {
          icon: <FaMobileAlt/>,
          title:'Mobile Development'
        },
        {
          icon: <GiSpiderWeb />,
          title:'Web Development'
        },
        {
          icon: <GiAbstract061 />,
          title:'Design'
        },
    ]

  return (
    <div className='service-container'>
        <h2 className='service-title'>Services that I provide</h2>
       <div className='service-cards-container'>
       {
        serviceData.map((item, index)=>{
            return(
               <div key={index} className='service-card'>
                    <div className="service-card-icon">
                        {item.icon}
                    </div>
                    <h4>{item.title}</h4>
               </div>
            )
        })
       }
       </div>
    </div>
  )
}
