import React from 'react';
import './Calendly.css';
import { InlineWidget } from "react-calendly";

export default function Calendly() {
  return (
    <div className='calendly'>
        <div className='calendly-header'>
            <p>Please make sure you will join the meeting.</p>
        </div>
        <div className='inlineWidget'>
        <InlineWidget url="https://calendly.com/i____saurabh/connect-with-saurabh?hide_event_type_details=1&background_color=222020&text_color=f8f8f8&primary_color=f81d46" />
        </div>
        
    </div>
  )
}
