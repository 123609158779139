
import chatApp from '../../assets/chatApp.png';
import nextBlog from '../../assets/nextBlog.png';
// import easyBank from '../../assets/easyBank.png';
import fitClub from '../../assets/fitClub.png';
import appleRedesign from '../../assets/appleRedign.jpg';
import uberEats from '../../assets/uberEats.png';
import translate from '../../assets/translate.png';


export const projects = [
  {
    name: "Google Translate Clone",
    img: translate,
    link: "https://github.com/saurabhkara/Google-Translate-Clone",
  },
  {
    name: "RN Chat App",
    img: chatApp,
    link: "https://github.com/saurabhkara/Chat-App-React-Native",
  },
  {
    name: "Next Blog",
    img: nextBlog,
    link: "https://github.com/saurabhkara/Nextjs-Blog",
  },
  {
    name: "Next Apple Redesign",
    img: appleRedesign,
    link: "https://www.google.com/",
  },
  {
    name: "Uber eats Clone",
    img: uberEats,
    link: "https://github.com/saurabhkara/uberEatsClone",
  },
  {
    name: "Fitclub project ",
    img: fitClub,
    link: "https://fitclubproject.pages.dev/",
  },
  // {
  //   name: "Easy Bank",
  //   img: easyBank,
  //   link: "https://easybank-2o4.pages.dev/",
  // },
];
