import React from "react";
import "./project.css";
import { projects } from "./ProjectList";

export default function Project() {
  return (
    <div>
        <h2>Some of my Projects</h2>
      <div className="projects-container">
        {projects.map((item, index) => {
          return (
            <div
            //   href={item.link}
              onClick={()=>window.open(item.link)}
            //   target="_blank"
              key={index}
              className="project-card"
            >
                <div className="project-img-container">
                    <img src={item.img} alt="project"  className="project-img"/>
                </div>
                <div className="project-card-title">
                    <h5>{item.name}</h5>
                </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
