import React, { useState } from "react";
import { LOGO, projectIcons, navbarItem } from "./NavbarConstant";
import "./Navbar.css";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [navMenu, setNavMenu] = useState(false);

  const closeMenu=()=>{
    setNavMenu(false);
  }
  window.addEventListener('scroll',closeMenu)

  const hamburger = (
    <a
      href="/#"
      className="hamburger-container"
      onClick={() => setNavMenu(!navMenu)}
    >
      <div className={navMenu ? "close-hamburger": "hamburger"}>
        <span className="ham-span"></span>
        <span className="ham-span"></span>
        <span className="ham-span"></span>
      </div>
    </a>
  );

  const dropDown = (
    <div className="show-nav-items">
      <div className="dropdown-close hamburger-container">{hamburger}</div>
      {navbarItem.map((item, index) => {
        return (
          <Link
            to={item.id}
            className="nav-item"
            onClick={() => setNavMenu(!navMenu)}
            key={index}
          >
            {item.name}
          </Link>
        );
      })}
    </div>
  );

  return (
    <div className="navbar">
      <div className="nav-icon-container">
        {projectIcons.map((item, index) => {

          return (
            <div className="navbar-icon"  onClick={()=>window.open(item[1])}>
              {item[0]}
            </div>
          );
        })}
      </div>
      <div className="logo-container">{LOGO}</div>
      {hamburger}
      {navMenu && dropDown}
    </div>
  );
}
