import React from "react";
import "./Navbar.css";
import { FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

export const projectIcons = [
   [<FaGithub />,"https://github.com/saurabhkara"], 
   [<FaLinkedin />,'https://www.linkedin.com/in/saurabhkumar802/'], 
   [<FaTwitter />,'#'], 
   
    
];

export const LOGO = (
  <Link to="/" className="logo">
    Saurabh
  </Link>
);

export const navbarItem = [
  {
    name: "Home",
    item: "/",
  },
  {
    name: "About",
    item: "about",
  },
  {
    name: "Projects",
    item: "/projects",
  },
  {
    name: "Contact",
    item: "/contact",
  },
];
