import React from "react";
import { LOGO, navbarItem, projectIcons } from "../navbar/NavbarConstant";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-nav-container">
        <div className="footer-navigation">
          {navbarItem.map((item, index) => {
            return (
              <a href={item.id} key={index} className="footer-nav-item">
                {" "}
                {item.name}
              </a>
            );
          })}
        </div>
        <div></div>
        <div className="footer-socials">
          {projectIcons.map((item, index) => {
            return (
              <a href={item[1]} className="footer-social-icon" key={index}>
                {item[0]}
              </a>
            );
          })}
        </div>
      </div>
      <div className="footer-last">
        <div className="footer-logo-container">{LOGO}</div>
        <h6>Copywrite @2022 Saurabh</h6>
      </div>
    </div>
  );
}
