import React from "react";
import "./about.css";
import avatar from "../../assets/avatar.jpeg";
import Calendly from "../calendly/Calendly";

export default function About() {
  return (
    <div className="about-container" id="about">
      <h2>
        Let's Connect <br />
        So that we can discuss about your next step.
      </h2>
      <div className="about-me-content">
        <div className="about-me-avatar">
          <div>
            <img src={avatar} alt="" />
          </div>
          <p>
            <strong>@Saurabh :</strong> Hi👋🏻, I am Saurabh. I design and develop
            modern web and mobile app using JavaScript and its framwwrok. Send
            me mail at saurabhkumar679@gmail.com for my latest resume. Thank You
          </p>
        </div>

        <div className="about-me-calender">
          <Calendly />
        </div>
      </div>
    </div>
  );
}
